<template>
  <div
    v-if="switchFlagModals && showDialNumber !== '____' && paternFlag"
    class="modal modal-channel"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <!--   <div class="modal-header">
                <h5 class="modal-title">Ir al canal</h5>
              </div> -->
        <div class="modal-body">
          <span class="channel-img">
            <img
              v-show="zshowChannel.logoCanal"
              :src="zshowChannel.logoCanal"
              alt="Channel Image"
            />
          </span>
          <span class="channel-switch">{{ showDialNumber }}</span>

          <div v-show="progressbar" class="progress">
            <div class="indeterminate"></div>
          </div>
        </div>
        <!--  <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal">Aceptar</button>
              </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { blockAsset } from "../helpers/parentalControl";

const TIME_OUT_DIAL = 1000;
const TIME_OUT_ZAPPING = 1000;
export default {
  name: "DialModal",
  data() {
    return {
      video: null,
      listChannel: {},
      timeOut: {},
      process: "",
      dial: "",
      zChannel: "",
      progressbar: true,
      zshowChannel: {},
      way: "up",
      paternFlag: false,
      switchFlagModals: Boolean,
      blockByParentalControl: false,
    };
  },
  mounted() {
    const self = this;
    telecentro.tplay.core.listChannel.obtenerCanales(function (ret) {
      self.listChannel = ret;
    });
    this.$bus.$on("hidenDial", this.checkFlagDial);
    this.$bus.$on("player:state-change", (state) => {
      // if (state=='START') {
      self.resetDial();
      self.$emit("showLoaderDial", false);
      // }
    });
  },
  beforeDestroy() {
    this.$bus.$off("hidenDial", this.checkFlagDial);
  },
  computed: {
    showDialNumber() {
      const self = this;
      let showdial = "____";
      if (
        self.dial.length > 0 &&
        !self.zshowChannel.lcn &&
        self.switchFlagModals
      ) {
        showdial = self.dial;
        for (let i = 4; i > self.dial.length; i--) {
          showdial += "_";
          self.checkPaternFLag(showdial);
        }
      } else if (self.zshowChannel.lcn) {
        showdial = self.zshowChannel.lcn;
        for (let i = 4; i > self.zshowChannel.lcn.length; i--) {
          showdial += "       ";
        }
      }

      return showdial;
    },
  },
  methods: {
    checkFlagDial(set) {
      this.switchFlagModals = set;
    },
    checkPaternFLag(dial) {
      // chequeo si se ingresan caracteres especiales si es asi retorna false y escondo el dialmodal y no realiza ninguna accion.
      let patern = /^[0-9*_]*$/;
      if (patern.test(dial)) {
        this.paternFlag = true;
      } else {
        this.paternFlag = false;
      }
    },
    dialNumber(key, channel) {
      const self = this;
      if (self.dial.length < 4) {
        self.dial = self.dial + key;
        // console.clear();
        clearTimeout(self.timeOut);
        // console.log('press: ' + self.dial);
        if (self.dial.length == 4) {
          self.process = self.dial;
          self.goToChanel(channel);
        } else {
          self.timeOut = setTimeout(() => {
            self.process = self.dial;
            self.goToChanel(channel);
          }, TIME_OUT_DIAL);
        }
      }
    },

    goToChanel(channel) {
      const self = this;
      self.deleteLeftZero(() => {
        if (self.process.length > 0) {
          const selectedChannel = parseInt(self.process);
          let found = false;
          let bk = {};
          if (
            parseInt(self.listChannel[0].lcn) < selectedChannel &&
            parseInt(self.listChannel[self.listChannel.length - 1].lcn) >
              selectedChannel
          ) {
            for (let i = 0; i < self.listChannel.length; i++) {
              if (self.listChannel[i].lcn === selectedChannel) {
                found = true;
                this.setChannelDial(channel, self.listChannel[i]);
              } else if (
                i > 0 &&
                !found &&
                parseInt(self.listChannel[i].lcn) > selectedChannel &&
                parseInt(self.listChannel[i - 1].lcn) < selectedChannel
              ) {
                bk = self.listChannel[i - 1];
                self.process = self.listChannel[i - 1].lcn;
              }
            }
            if (!found) {
              this.setChannelDial(channel, bk);
            }
          } else if (parseInt(self.listChannel[0].lcn) >= selectedChannel) {
            self.process = self.listChannel[0].lcn;
            this.setChannelDial(channel, self.listChannel[0]);
          } else if (
            parseInt(self.listChannel[self.listChannel.length - 1].lcn) <=
            selectedChannel
          ) {
            self.process = self.listChannel[self.listChannel.length - 1].lcn;
            this.setChannelDial(
              channel,
              self.listChannel[self.listChannel.length - 1]
            );
          }
        } else {
          this.setChannelDial(channel, self.listChannel[0]);
        }
      });
    },
    setChannelDial(channel, next) {
      const self = this;
      self.zshowChannel = next;

      if (typeof channel === "undefined" || channel.lcn !== next.lcn) {
        self.progressbar = true;
        self.$emit("showLoaderDial", true);
        setTimeout(() => {
          self.resetDial();
          this.paternFlag = false;
        }, 3000);
        telecentro.tplay.core.epg.obtenerProgramacionHoy(
          next.idChannel,
          false,
          (prog) => {
            prog.forEach((element) => {
              if (element.status == "PRESENTE") {
                if (this.paternFlag) {
                  this.paternFlag = false;
                  self.blockByParentalControl = blockAsset(element);
                  if (self.blockByParentalControl) {
                    //console.log('entro en setChannelDIAL');
                    self.$bus.$emit("modal-pin:show", function () {
                      self.blockByParentalControl = false;
                      self.$bus.$emit("player:set-source", element);
                    });
                  } else {
                    self.$bus.$emit("player:set-source", element);
                  }
                }

                tplay.sendAudience(element, "LCN");
              }
            });
          }
        );
      } else {
        setTimeout(() => {
          self.resetDial();
        }, 700);
      }
    },
    deleteLeftZero(onFinish) {
      while (this.process[0] == 0) {
        this.process = this.process.slice(1);
      }
      // funcion Callback
      if (typeof onFinish === "function") {
        onFinish();
      }
    },
    zapChannel(channel, way) {
      const self = this;
      // console.log(channel);

      self.zChannel =
        self.zChannel === "" ? self.getKeyChannel(channel) : self.zChannel;
      let step = way === "down" ? self.zChannel - 1 : self.zChannel + 1;
      if (step >= 0 && step < self.listChannel.length) {
        self.zChannel = step;
        this.setChannel(step, channel);
      } else if (step < 0) {
        self.zChannel = self.listChannel.length - 1;
        this.setChannel(self.listChannel.length - 1, channel);
      } else if (step >= self.listChannel.length) {
        self.zChannel = 0;
        this.setChannel(0, channel);
      }
    },
    getKeyChannel(channel) {
      const self = this;
      if (typeof channel !== "undefined") {
        for (let i = 0; i < self.listChannel.length; i++) {
          if (self.listChannel[i].lcn === channel.lcn) {
            return i;
          }
        }
      }
      return 0;
    },
    setChannel(next, channel) {
      const self = this;
      self.resetDial(); // si había un marcado previo se pisa
      clearTimeout(self.timeOut);
      let nextChannel = self.listChannel[next];
      // console.clear();
      // console.log('va a el canal: '+ nextChannel.lcn + ' ' + nextChannel.name);
      self.zshowChannel = nextChannel;
      self.timeOut = setTimeout(() => {
        if (typeof channel === "undefined" || channel.lcn !== nextChannel.lcn) {
          self.zChannel = "";
          self.progressbar = true;
          self.$emit("showLoaderDial", true);
          telecentro.tplay.core.epg.obtenerProgramacionHoy(
            nextChannel.idChannel,
            false,
            (prog) => {
              prog.forEach((element) => {
                if (element.status == "PRESENTE") {
                  // self.$bus.$emit('player:set-source', element);
                  self.blockByParentalControl = blockAsset(element);
                  if (self.blockByParentalControl) {
                    //console.log('entro en setChannel');
                    self.$bus.$emit("modal-pin:show", function () {
                      self.blockByParentalControl = false;
                      self.$bus.$emit("player:set-source", element);
                    });
                  } else {
                    self.$bus.$emit("player:set-source", element);
                  }
                  tplay.sendAudience(element, "LCN");
                }
              });
            }
          );
        } else {
          self.resetDial();
          self.zChannel = "";
        }
      }, TIME_OUT_ZAPPING);
    },
    resetDial() {
      const self = this;
      self.zshowChannel = {};
      self.progressbar = false;
      self.process = "";
      self.dial = "";
    },
  },
};
</script>
<!-- <style scoped>

  Se comenta estilo porque no se esta utilizando en el componente
  
.showflagDial {
  display: none !important;
}
</style> -->
